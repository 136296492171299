import React from 'react';
import { Detail } from './detail';
import { Menu } from './menu';
import { Provider } from 'react-redux';
import { createGlobalStore } from '../state';

const Home = () => {
  const [store] = React.useState(createGlobalStore());

  return (
    <Provider store={store}>
      <div className="home">
        <Menu />
        <Detail />
      </div>
    </Provider>
  );
}

export default Home;
