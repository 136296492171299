import * as React from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "../state";
import "./detail.css";

export const Detail: React.FC = () => {
  const id = useSelector((state: GlobalState) => state.selectedID);
  const moments = useSelector((state: GlobalState) => state.moments);

  if (!id || !moments) {
    // Splash - explanation about how to use
    // and point it at a directory.
    return null;
  }

  const moment = moments[id];
  if (!moment) {
    return null;
  }

  return (
    <div className="detail">
      <DetailCam view={"front"} file={moment.front} />
      <DetailCam view={"rear"} file={moment.rear} />
      <DetailCam view={"left"} file={moment.left} />
      <DetailCam view={"right"} file={moment.right} />
    </div>
  );
}

interface DetailCamProps {
  view: string;
  file?: File;
}

export const DetailCam: React.FC<DetailCamProps> = ({ view, file }) => {
  let content: JSX.Element | null = null;

  if (!file) {
    content = (
      <div className="detail__no-video">
        {`No ${view} cam video found!`}
      </div>
    )
  } else {
    const src = webkitURL.createObjectURL(file);

    content = (
      <video
        className="detail__video"
        src={src}
        loop
        controls
        // @ts-ignore
        autoplay="autoplay"
      />
    )
  }

  return (
    <div className="detail__moment">
      {content}
      {/* <div className="detail__moment-view">
        {view}
      </div> */}
    </div>
  );
}