import { Moment } from "./index";

export enum ActionType {
  VideosUpdated = "VideosUpdated",
  SelectID = "SelectID"
}

export interface VideosUpdated {
  type: ActionType.VideosUpdated,
  moments: Record<string, Moment> | null;
}

export interface SelectID {
  type: ActionType.SelectID,
  id: string;
}

export type Action = VideosUpdated | SelectID;

export function selectID(id: string) {
  return {
    type: ActionType.SelectID,
    id,
  };
}

export function updateVideos(fileList: FileList | Object | null) {
  const info = getVideosFromFileList(fileList);

  return {
    type: ActionType.VideosUpdated,
    ...info,
  }
}

export interface DirInfo {
  moments: Record<string, Moment> | null;
}

export function getVideosFromFileList(fileList: FileList | Object | null): DirInfo {
  // Validate dir exists
  if (!fileList || !(fileList instanceof FileList)) {
    return {
      moments: null,
    };
  }

  const moments: Record<string, Moment> = {};
  
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);
    const fileNameDoesNotMatchPattern = 
      /\d+-\d+-\d+_\d+-\d+-\d+-[a-zA-Z]+(_[a-zA-Z]+)?\.mp4/.test(file?.name ?? "");

    if (!file || file.size < 1000 || !fileNameDoesNotMatchPattern) {
      continue;
    }

    const [date, timeAndView] = file.name.replace(".mp4", "").split("_");
    const [h, m, s, view] = timeAndView.split("-");
    const time = `${h}:${m}:${s}`;
    const id = `${date} ${time}`;

    const moment = {
      ...moments[id],
      id,
      date,
      time
    };

    if (view === "front") {
      moment.front = file;
    } else if (view === "back") {
      moment.rear = file;
    } else if (view === "left") {
      moment.left = file;
    } else if (view === "right") {
      moment.right = file;
    }

    moments[id] = moment;
  }

  // Return obj
  return {
    moments
  };
}