import { createStore } from "redux";
import { Action, ActionType } from "./actions";

export interface GlobalState {
  moments: Record<string, Moment> | null;
  selectedID: string | null;
}

export enum ErrorType {
  NoDir = "NoDir",
  NoVideosInDir = "NoVideosInDir"
}

export interface Error {
  type: ErrorType;
}

export interface Moment {
  id: string;
  date: string;
  time: string;
  front?: File;
  rear?: File;
  left?: File;
  right?: File;
}

export const getInitialState = (): GlobalState => ({
  moments: null,
  selectedID: null
});

export function reducer(state = getInitialState(), action: Action): GlobalState {
  switch (action.type) {
    case ActionType.VideosUpdated:
      const selectedID = action.moments ? Object.keys(action.moments)[0] : null;

      return {
        ...state,
        moments: action.moments,
        selectedID
      }
    case ActionType.SelectID:
      return {
        ...state,
        selectedID: action.id
      }
    default:
      return state;
  }
}

export function createGlobalStore() {
  return createStore(reducer);
}