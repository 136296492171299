import { Moment } from "./";

type Moments = Record<string, Moment>;

/**
 * MM/DD/YYYY -> Moment[]
 */
type OrganizedMoments = Record<string, Moment[]>;

export function getOrganizedMomentsByDate(moments: Record<string, Moment>) {
  const allMoments = Object.keys(moments);
  const dayRecord = allMoments.reduce((days, id) => {
    const moment = moments[id];
    if (!days[moment.date]) {
      days[moment.date] = [];
    }
    days[moment.date] = [moment, ...days[moment.date]].sort((a, b) => b.time.localeCompare(a.time));
    return days;
  }, {} as OrganizedMoments);

  return dayRecord;
}