import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState, Moment } from "../state";
import { updateVideos, selectID } from "../state/actions";
import { getOrganizedMomentsByDate } from "../state/utils";
import cx from "classnames";
import "./menu.css";

interface Props {
  selectedID?: string;
}

export const Menu: React.FC<Props> = () => {
  return (
    <menu className="menu">
      <h1 className="menu__title">
        {"Lookout!"}
      </h1>
      <div className="menu__subtitle">
        {"No fuss TeslaCam viewing."}
      </div>
      <DirectorySection />
      <MomentsSection />
    </menu>
  );
}

export const DirectorySection: React.FC = () => {
  const dispatch = useDispatch();

  const onChooseFiles = (evt: React.FormEvent<HTMLInputElement>) => {
    // @ts-ignore
    dispatch(updateVideos(evt.target.files as FileList));
  }

  return (
    <div className="menu__section">
      <div className="menu__section-title">
        {"Load Videos"}
      </div>
      <input
        type="file"
        name="files[]"
        accept="video/mp4"
        className="menu__choose-files"
        onChange={onChooseFiles}
        multiple
      />
    </div>
  );
}

export const MomentsSection: React.FC = () => {
  const moments = useSelector((state: GlobalState) => state.moments);
  const organizedMoments = React.useMemo(() => {
    return getOrganizedMomentsByDate(moments ?? {});
  }, [moments]);

  let content: JSX.Element | null = null;

  if (organizedMoments && Object.keys(organizedMoments).length === 0) {
    content = (
      <div className="menu__error">
        {"No TeslaCam moments found. Try another selection."}
      </div>
    )
  } else if (organizedMoments) {
    content = (
      <>
        {Object.keys(organizedMoments).map(key => {
          return (
            <MomentDay date={key} moments={organizedMoments[key]} />
          );
        })}
      </>
    );
  } else {
    content = (
      <div className="menu__error">
        {"Load some videos to get started!"}
      </div>
    )
  }

  return (
    <div className="menu__section">
      <div className="menu__section-title">
        {"Moments"}
      </div>
      {content}
    </div>
  )
}

export const MomentDay: React.FC<{ date: string, moments: Moment[]}> = ({ date, moments }) => {
  const [hidden, setHidden] = React.useState(false);
  const toggleHidden = React.useCallback(() => setHidden(!hidden), [hidden]);

  return (
    <div className="menu__date" key={date}>
      <button className="menu__day-button" onClick={toggleHidden}>
        {`${date} ${hidden ? `(${moments.length} hidden)` : ""}`}
      </button>
      {!hidden && 
        <ul className="menu__list">
          {moments.map(moment => (
            <MomentRow moment={moment} />
          ))}
        </ul>
      }
    </div>
  )
}

export const MomentRow: React.FC<{ moment: Moment }> = ({ moment }) => {
  const isSelected = useSelector((state: GlobalState) => state.selectedID) === moment.id;
  const dispatch = useDispatch();

  const onClick = () => dispatch(selectID(moment.id));

  const classes = cx("menu__item", {
    "menu__item--selected": isSelected
  });

  return (
    <li className={classes} key={moment.id}>
      <button onClick={onClick} className="menu__item-button">
        {moment.time}
      </button>
    </li>
  )
}